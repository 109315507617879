<template>
    <div id="data-list-thumb-view" class="data-list-container">
        <vs-table ref="table" multiple v-model="selected" :data="orders">

            <template slot="thead">
                <vs-th sort-key="id">رقم الطلب</vs-th>
                <vs-th sort-key="total">المبلغ الإجمالي</vs-th>
                <vs-th sort-key="created_at">تاريخ التوصيل</vs-th>

                <vs-th>للزبون</vs-th>
                <vs-th>رقم الزبون</vs-th>
                <vs-th>سعر الدعم</vs-th>
                <vs-th>المدفوع</vs-th>
                <vs-th>إستعادة</vs-th>
            </template>

            <template slot-scope="{ data }">
                <tbody>
                    <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                        <vs-td>
                            <p class="product-name font-medium truncate">{{ tr.id }}</p>
                        </vs-td>

                        <vs-td>
                            <p class="product-category">{{ tr.total }}LYD</p>
                        </vs-td>

                        <vs-td>
                            <vs-chip class="product-order-status">
                                {{ tr.delivery_date }}
                            </vs-chip>
                        </vs-td>


                        <vs-td>
                            {{tr.user ? tr.user.username : 'هذا المستخدم قام بمسح حسابه' }}
                        </vs-td>
                        <vs-td>
                            {{tr.user ? tr.user.phone : 'هذا المستخدم قام بمسح حسابه' }}
                        </vs-td>

                        <vs-td>
                            {{ tr.support }}
                        </vs-td>
                        <vs-td>
                            {{ tr.payment_method_id !== 1 ? `${tr.paid} د.ل ` : 'مدفوع نقدي' }}
                        </vs-td>
                        <vs-td>
                            <feather-icon icon="RefreshCwIcon" @click="confirm(tr.id)" svgClasses="h-4 w-4" />
                        </vs-td>
                    </vs-tr>
                </tbody>
            </template>
        </vs-table>
        <vs-pagination class="mt-4" :total="lastPage" v-model="current_Page" @change="getData(current_Page)">
        </vs-pagination>
    </div>
</template>
  
  
<script>
import moduleOrder from "@/store/orders/moduleOrder.js";
export default {
    data() {
        return {
            search: '',
            selected: [],
            orders: [],
            isMounted: false,
            status: '',
            addNewDataSidebar: false,
            sidebarData: {},
            current_Page: 1,
            itemsPerPage: 5,
            lastPage: 0,
            order_id: '',
            totalItems: 0,
        };
    },
    computed: {
        currentPage() {
            if (this.isMounted) {
                return this.$refs.table.currentx;
            }
            return 0;
        },
        queriedItems() {
            return this.$refs.table ?
                this.$refs.table.queriedResults.length :
                this.products.length;
        },
    },

    methods: {
        getData(page) {

            this.$store.dispatch("orders/fetchArchived", page).then((response) => {
                this.orders = response.data.orders.data;
                this.itemsPerPage = response.data.orders.per_page;
                this.totalItems = response.data.orders.total;
                this.lastPage = response.data.orders.last_page;

                this.$vs.loading.close();
            });
        },
        confirm(id) {
            this.order_id = id;
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: "تأكيد",
                text: "هل انت متأكد من إستعادة هذا الطلب",
                acceptText: "تأكيد",
                cancelText: "الغاء",
                accept: this.retrieve,
            });
        },
        retrieve() {
            this.$store
                .dispatch("orders/retrieve", this.order_id)
                .then(() => {
                    this.getData();
                    this.$vs.notify({
                        title: "تم بنجاح",
                        text: "تم استعادة الطلب",
                        color: "success",
                    });
                })
                .catch(() => {
                    this.$vs.notify({
                        title: "حدث خطأ",
                        text: "يرجى اعادة المحاولة",
                        color: "danger",
                    });
                });
        },
    },
    mounted() {
        this.$vs.loading()
    },
    created() {
        if (!moduleOrder.isRegistered) {
            this.$store.registerModule("orders", moduleOrder);
            moduleOrder.isRegistered = true;
        }

    },
};

</script>
  
<style lang="scss">
#data-list-thumb-view {
    .vs-con-table {
        .product-name {
            max-width: 23rem;
        }

        .vs-table--header {
            display: flex;
            flex-wrap: wrap-reverse;
            margin-left: 1.5rem;
            margin-right: 1.5rem;

            >span {
                display: flex;
                flex-grow: 1;
            }

            .vs-table--search {
                padding-top: 0;

                .vs-table--search-input {
                    padding: 0.9rem 2.5rem;
                    font-size: 1rem;

                    &+i {
                        left: 1rem;
                    }

                    &:focus+i {
                        left: 1rem;
                    }
                }
            }
        }

        .vs-table {
            border-collapse: separate;
            border-spacing: 0 1.3rem;
            padding: 0 1rem;

            tr {
                box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);

                td {
                    padding: 10px;

                    &:first-child {
                        border-top-left-radius: 0.5rem;
                        border-bottom-left-radius: 0.5rem;
                    }

                    &:last-child {
                        border-top-right-radius: 0.5rem;
                        border-bottom-right-radius: 0.5rem;
                    }

                    &.product-img-container {
                        height: 110px;

                        // background: #fff;

                        span {
                            display: flex;
                            justify-content: flex-start;
                        }

                        .product-img {
                            height: 68px;
                        }
                    }
                }

                td.td-check {
                    padding: 20px !important;
                }
            }
        }

        .vs-table--thead {
            th {
                padding-top: 0;
                padding-bottom: 0;

                .vs-table-text {
                    text-transform: uppercase;
                    font-weight: 600;
                }
            }

            th.td-check {
                padding: 0 15px !important;
            }

            tr {
                background: none;
                box-shadow: none;
            }
        }

        .vs-table--pagination {
            justify-content: center;
        }
    }
}
</style>
  