import axios from "@/axios.js"

export default {
    fetchOrders({commit},params){
        return new Promise((resolve,reject)=>{
            axios.get(`api/dashboard/orders/index`,{params:params})
            .then((response)=>{
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },

    fetchAllOrders({commit},params){
        return new Promise((resolve,reject)=>{
            axios.get(`api/dashboard/orders/orders`,{params:params})
            .then((response)=>{
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    fetchArchived({commit}){
        return new Promise((resolve,reject)=>{
            axios.get(`api/dashboard/orders/archived`)
            .then((response)=>{
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    retrieve({commit},id){
        return new Promise((resolve,reject)=>{
            axios.post(`api/dashboard/orders/retrieve/${id}`)
            .then((response)=>{
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },

    

    createNewOrder({commit},payload){
        return new Promise((resolve,reject)=>{
            axios.post('api/dashboard/orders/store',payload)
            .then((response)=>{
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    createNewOrderbyNewUser({commit},payload){
        return new Promise((resolve,reject)=>{
            axios.post('api/dashboard/orders/newuser',payload)
            .then((response)=>{
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },

    updateOrder({commit},payload){
        return new Promise((resolve,reject)=>{
            axios.post(`api/dashboard/orders/update/${payload.get('order_id')}`,payload)
            .then((response)=>{
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    updateOrderStatus({commit},payload){
        return new Promise((resolve,reject)=>{
            axios.post(`api/dashboard/orders/update-status/${payload.get('id')}`,payload)
            .then((response)=>{
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    editOrder({commit},itemId){
        return new Promise((resolve,reject)=>{
            axios.get(`api/dashboard/orders/edit/${itemId}`)
            .then((response)=>{
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    getOrderInvoice({commit},itemId){
        return new Promise((resolve,reject)=>{
            axios.get(`api/dashboard/orders/show/${itemId}`)
            .then((response)=>{
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },

    deleteOrder({commit},id){
        return new Promise((resolve,reject)=>{
            axios.get(`api/dashboard/orders/delete/${id}`)
            .then((response)=>{
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },


    getPaymentMethods({commit},page){
        return new Promise((resolve,reject)=>{
            axios.get(`api/dashboard/payment-methods/index`)
            .then((response)=>{
                commit('SET_PAYMENT_METHOD',response.data.payment_methods)


                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
}